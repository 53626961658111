import { fullError } from './util';
import Cookies from 'js-cookie';
import {t} from "i18next";

export const loginProcess = (result, setIsRestaurantsModalOpen, setIsEmailToVerify, setIsLoggedIn, setShowError) => {
    if (result.result === 'OK') {
        Cookies.set('xAuthToken', result.headers.get('X-Auth-Token'), { expires: 1 });
        Cookies.set('jwtrt', result.data.refreshToken, { expires: 7 });
        Cookies.set('userId', result.data.userId, { expires: 7 });
        Cookies.set('username', result.data.username, { expires: 7 });

        if (result.data.roles.length > 0 && result.data.roles[0].role === 'admin') {
            setIsRestaurantsModalOpen(true);
        } else {
            Cookies.set('isLoggedIn', true, { expires: 7 });
            if (result.data.roles.length > 0 && result.data.roles[0].role === 'restaurateur') Cookies.set('rid', result.data.roles[0].roleOn, { expires: 7 });
            setIsEmailToVerify(false);
            setIsLoggedIn(true);
            setShowError(false);

        }
    } else {
        setIsLoggedIn(false);
        setShowError(true);
        fullError(t('anErrorOccurredDuringLogin'), result);
    }
}