import React from 'react';
/** Bootstrap */
import { Modal, Button } from 'react-bootstrap';
/** Services */

import { useTranslation } from 'react-i18next';
import TermsPlain from './TermsPlain';

const TermsModal = (props) => {
    const { t } = useTranslation();
    
    return (
        <Modal className="termsAndConditions modal" show={props.isModalOpen} onHide={() => props.setIsModalOpen(false)} size="lg" centered>
            <Modal.Body className="px-4 bodyContainer">
            <TermsPlain noStyle={true}/>
            </Modal.Body>
            <Modal.Footer>
                <Button className="d-sm-none mx-auto" variant="secondary" onClick={() => props.setIsModalOpen(false)}>
                    {t('close')}
                </Button>
                <Button className="d-none d-sm-block mx-auto w-25" variant="secondary" onClick={() => props.setIsModalOpen(false)}>
                    {t('close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TermsModal;