
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteMenuFetch, updateMenuFetch } from '../services/api';

import { fullError, showDangerToast, showSuccessToast } from '../services/util';

const MenuType = (props) => {
    const { t } = useTranslation();
    const [newMenuTypeName, setNewMenuTypeName] = useState('');
    const [newStartingPrice, setNewStartingPrice] = useState('');
    const [isEdit, setIsEdit] = useState(false);

    const EditMenuTypeEvent = async () => {
        var EditMenuTypeObj = {
            "_id": props.menuTypeObj._id,
            "name": newMenuTypeName,
            "starting_price": newStartingPrice
        }

        if (!props.menuTypeList.some(m => (m.name.toLowerCase().trim() === newMenuTypeName.toLowerCase().trim()) && m._id !== props.menuTypeObj._id)) {
            if (newMenuTypeName !== '') {
                try {
                    props.setShowLoading(true);
                    const menuFetch = await updateMenuFetch(props.menuTypeObj._id, EditMenuTypeObj.name, EditMenuTypeObj.starting_price);
                    if (menuFetch.result === 'OK') {
                        props.setMenuTypeList(prevList => {
                            var tempList = [...prevList];
                            tempList = tempList.map(mt =>
                                mt._id === EditMenuTypeObj._id ? EditMenuTypeObj : mt
                            );
                            return tempList;
                        });
                        setIsEdit(false);
                        showSuccessToast(t('menuEditedCorrectly'));

                    } else {
                        fullError(t('anErrorOccurredDuringMenuUpdate'), menuFetch);
                    }

                } catch (error) {
                    fullError(t('anErrorOccurredDuringMenuUpdate'), error);
                } finally {
                    props.setShowLoading(false);
                }
            } else {
                showDangerToast(t('enterMenuName'));
            }
        } else {
            showDangerToast(t('menuAlreadyExists'));
        }
    };
    const DeleteMenuTypeEvent = async () => {
        if (await window.confirm(t('confirmDeletion'))) {
            try {
                props.setShowLoading(true);
                const menuFetch = await deleteMenuFetch(props.menuTypeObj._id);
                if (menuFetch.result === 'OK') {
                    props.setMenuTypeList(prevList => {
                        var tempList = [...prevList];
                        tempList = tempList.filter(mt => mt._id !== props.menuTypeObj._id);
                        return tempList;
                    });
                    localStorage.removeItem('menuTypeSelected');
                    props.setIsAllowedToClose(false);

                    setIsEdit(false);
                    props.setShowLoading(false);
                    showSuccessToast(t('menuDeletedCorrectly'));

                } else {
                    fullError(t('anErrorOccurredDuringMenuDeletion'), menuFetch);
                }
            } catch (error) {
                fullError(t('anErrorOccurredDuringMenuDeletion'), error);
            } finally {
                props.setShowLoading(false);
            }
        }
    };
    const handleKeyUp = (event) => {
        if (event.key === 'Enter') {
            EditMenuTypeEvent();
        }
    }
    const handleEditClick = () => {
        setIsEdit(true);
        setNewMenuTypeName(props.menuTypeObj.name);
        setNewStartingPrice(props.menuTypeObj.starting_price);
    }
    return (
        <div key={'menu-type-box-' + props.menuTypeObj._id}
            className={((props.isAdmin && !props.isStaff) ? "isAdmin " : "") + "menuTypeBox " + (isEdit ? "input-group d-flex align-items-center mb-1" : "btn-group d-flex align-items-center")}>

            {isEdit ?
                <>
                    <div className="d-flex align-items-center align-self-stretch ">
                        <input onChange={(event) => setNewMenuTypeName(event.target.value)} onKeyUp={handleKeyUp} value={newMenuTypeName}
                            type="text" id="newMenuTypeName" className="form-control align-self-stretch newMenuTypeName" placeholder="Inserisci il nome del menù"
                            disabled={props.showLoading} />

                        <div className="startingPriceBox input-group d-flex align-self-stretch input-group d-flex flex-row align-items-center mx-2">
                            <span className="input-group-text align-self-stretch menuTypeBase">Base</span>
                            <input onChange={(event) => setNewStartingPrice(event.target.value)} onKeyUp={handleKeyUp} value={newStartingPrice}
                                type="text" id="startingPrice" className="form-control align-self-stretch startingPrice"
                                placeholder="Prezzo di partenza" disabled={props.showLoading} />
                            <span className="input-group-text d-flex align-items-center justify-content-center align-self-stretch currency">€</span>
                        </div>
                        <button onClick={() => { EditMenuTypeEvent() }} type="button"
                            className={"btn btn-primary changeMenu menuType confirmMenuTypeEdit bi bi-check align-self-stretch"} disabled={props.showLoading}>
                        </button>
                    </div>
                </>
                :
                <button key={'menu-type-' + props.menuTypeObj._id} onClick={() => props.chooseTypeMenu(props.menuTypeObj)}
                    className={"btn btn-primary menuType text-white mb-1 align-self-stretch " +
                        (props.menuType && props.menuType._id === props.menuTypeObj._id ? "selected" : '')} disabled={props.showLoading}>
                    {props.menuTypeObj.name}
                </button>
            }
            {props.isAdmin && !props.isStaff &&
                <>
                    {!isEdit &&
                        <div className="btn-group d-flex flex-column align-items-stretch">
                            <button onClick={() => handleEditClick()} type="button"
                                className="btn btn-warning changeMenu menuType editMenuType bi bi-pencil" disabled={props.showLoading}>
                            </button>
                            <button onClick={() => { DeleteMenuTypeEvent(true) }} type="button"
                                className="btn btn-danger changeMenu menuType removeMenuType bi bi-trash mb-1 mx-0" disabled={props.showLoading}>
                            </button>
                        </div>
                    }
                </>
            }
        </div>

    );
};

export default MenuType;