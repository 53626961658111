import '../style/TableList.css';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/** Services */
import { fetchTableList, createTableFetch, deleteTableFetch, openSessionFetch, closeSessionFetch } from '../../services/api';
import QrCodeScannerModal from '../modals/QrCodeScannerModal';

import { fullError, showSuccessToast, sendMessage } from '../../services/util';
import Cookies from 'js-cookie';

function TableList(props) {
    const { t } = useTranslation();
    const [componentReady, setComponentReady] = useState(false);

    var [tables, setTables] = useState([]);
    var [tableCount, setTableCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentTableObj, setCurrentTableObj] = useState(false);


    useEffect(() => {
        if (componentReady && (!tables || tables.length === 0)) {
            fetchTableData();
        }
    }, [tables, componentReady]);


    useEffect(() => {
        // Assicurati che tutte le proprietà siano state settate
        if (props.isAdmin !== undefined && componentReady === false) {
            setComponentReady(() => {
                return true;
            });
        }
    }, [props.isAdmin, componentReady]);

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const fetchTableData = async () => {
        try {
            /** API */
            const fetchData = async () => {
                props.setShowLoading(true);
                try {
                    // if (tables.length === 0) {
                    const tableList = await fetchTableList();
                    var tempTableList = [];
                    tableList.data.forEach(function (table) {
                        const tempTable = { ...table, name: t('table') + ' ' + table.number, assigned: (!!table.users && table.users.length > 0) ? true : false };
                        tempTableList.push(tempTable)
                    });
                    setTables(tempTableList);
                    setTableCount(tempTableList.length);
                    // }
                } catch (error) {
                    // Gestisci l'errore secondo le tue esigenze
                } finally {
                    props.setShowLoading(false);
                }
            };
            fetchData();
        } catch (error) {
            fullError(t('anErrorOccurredDuringTablesLoading'), error);
        } finally {
            // props.setShowLoading(false);
        }
    }
    useEffect(() => {
        props.setPageTo('tableList');
    }, [props.setPageTo]);
    const createTable = async () => {
        try {
            props.setShowLoading(true);

            const tableNumber = tableCount + 1;

            const tableFetch = await createTableFetch(tableNumber, 4);
            if (tableFetch.result === 'OK') {
                setTableCount(tableNumber);
                fetchTableData();
                showSuccessToast(t('tableCreatedSuccessfully'));
            } else {
                fullError(t('anErrorOccurredDuringTableCreation'), tableFetch);
            }
        } catch (error) {
            fullError(t('anErrorOccurredDuringTableCreation'), error);
        } finally {
            props.setShowLoading(false);
        }
    }
    const acceptance = async (tableObj, userId) => {
        try {
            props.setShowLoading(true);

            const tableFetch = await openSessionFetch(tableObj.number, [userId]);
            if (tableFetch.result === 'OK') {
                sendMessage('qrcode_scan_' + userId, 'isAssigned', Cookies.get('rid'));
                fetchTableData();
                showSuccessToast(t('tableAssignedSuccessfully'));
            } else {
                fullError(t('anErrorOccurredDuringTableAssignment'), tableFetch);
            }
        } catch (error) {
            if (error.response.data.msg === "One or more user already in an opened session") {
                fullError(t('tableAlreadyAssigned'), error);
            } else {
                fullError(t('anErrorOccurredDuringTableAssignment'), error);
            }

        } finally {
            props.setShowLoading(false);
        }
    }
    const preModalProcess = (tableObj) => {
        setCurrentTableObj(tableObj);

        setIsModalOpen(true);
    }
    const closeTable = async (tableObj) => {
        if (await window.confirm(t('confirmTableClosing'))) {
            try {
                props.setShowLoading(true);
                const tableFetch = await closeSessionFetch(tableObj.session);
                if (tableFetch.result === 'OK') {
                    sendMessage('table_closing_' + tableObj.session, 'isClosed');
                    fetchTableData();
                    showSuccessToast(t('tableClosedSuccessfully'));
                } else {
                    fullError(t('anErrorOccurredDuringTableClosure'), tableFetch);
                }

            } catch (error) {
                fullError(t('anErrorOccurredDuringTableClosure'), error);
            } finally {
                props.setShowLoading(false);
            }

        }
    }
    const deleteTable = async (tableObj) => {
        if (await window.confirm(t('confirm'))) {
            try {
                props.setShowLoading(true);

                const tableFetch = await deleteTableFetch(tableObj._id);
                if (tableFetch.result === 'OK') {
                    fetchTableData();
                    showSuccessToast(t('tableCancelledCorrectly'));
                } else {
                    fullError(t('anErrorOccurredDuringTableDeletion'), tableFetch);
                }
            } catch (error) {
                fullError(t('anErrorOccurredDuringTableDeletion'), error);
            } finally {
                props.setShowLoading(false);
            }
        }
    }

    return (
        <>
            <a id="admin-section" className="adminSection"></a>
            <div className="container list mt-3 mb-5">
                {tables.length === 0 &&
                    <h4>
                        La lista tavoli è vuota
                    </h4>
                }
                <QrCodeScannerModal obj={currentTableObj} showLoading={props.showLoading} acceptance={acceptance}
                 isModalOpen={isModalOpen} closeModal={closeModal} />
                {
                    tables.map((tableObj) => (
                        <div key={tableObj._id} className="d-flex align-items-center customerRow">
                            <div className="d-flex align-items-center customerBox">
                                <img alt="" className="table" src={'img/svg/table.svg'} />
                                <div className="me-2 name table text-nowrap">{tableObj.name}</div>
                                {tableObj.assigned &&
                                    <div className="d-flex align-items-center flex-column flex-sm-row customerBox">
                                        {tableObj.users.map((u) => (
                                            <div key={'user-' + (u._id || u) + '-' + tableObj._id} className="me-2 text-center">
                                                <i className="bi bi-person-fill ms-1 me-1 icon"></i>
                                                <div className="ms-2 w-100 name">{u.username || u}</div>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                            <div className="confirmBox d-flex justify-content-end align-items-center w-100">
                                {!tableObj.assigned
                                    ? (
                                        <button onClick={() => preModalProcess(tableObj)} type="button"
                                            className="btn btn-primary me-1 bi bi-qr-code">
                                            <span className="d-none d-sm-block">{t('assign')}</span>
                                        </button>
                                    )
                                    : (
                                        <div className="d-flex">
                                            <button onClick={() => closeTable(tableObj)} type="button"
                                                className="btn btn-danger me-1 ms-2 bi bi-x">
                                                <span className="d-none d-sm-block">{t('close')}</span>
                                            </button>
                                        </div>
                                    )}
                            </div>
                        </div>
                    ))
                }

                {!props.isStaff ?
                    <>
                        <button onClick={() => deleteTable(tables[tables.length - 1])} type="button"
                            className="btn btn-danger me-1 bi bi-trash w-100 mt-3">
                            <span className="d-block">{t('deleteOneTable')}</span>
                        </button>
                        <div className="position-fixed ordinaContainer orderListBottomMenu py-1 tableListBottomBox">
                            <div className="d-flex w-100 justify-content-center align-self-stretch">
                                <button onClick={() => createTable()} type="button" className="btn btn-primary">
                                    {t('create')}
                                </button>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="position-fixed ordinaContainer orderListBottomMenu py-1 tableListBottomBox">
                        </div>
                    </>

                }
            </div>
        </>
    );
}
export default TableList;