import React, { useState, useEffect } from 'react';
/** Bootstrap */
import { Modal } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import { fetchRestaurants } from '../../services/api';
import Cookies from 'js-cookie';


const RestaurantsModal = (props) => {
    const { t } = useTranslation();
    const [restaurants, setRestaurants] = useState([]);

    // useEffect(() => {
    const fetchData = async () => {
        if (props.isRestaurantsModalOpen === true) {
            const result = await fetchRestaurants();
            if (result.result === 'OK') {
                setRestaurants(result.data);
            }
        }
    }
    fetchData();
    // }, [props.isRestaurantsModalOpen]);
    const chooseRestaurant = (rid) => {
        Cookies.set('rid', rid, { expires: 7 });
        Cookies.set('isLoggedIn', true, { expires: 7 });
        props.setIsLoggedIn(true);
    }
    return (
        <Modal className="modal" show={props.isRestaurantsModalOpen} onHide={() => props.setIsRestaurantsModalOpen(false)} backdrop="static" keyboard={false} centered>
            <Modal.Header>
                <Modal.Title>{t('chooseTheRestaurant')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-0 px-sm-2">
                {restaurants.map(r => (
                    <button key={r._id} onClick={() => chooseRestaurant(r._id)}
                        className={"btn btn-primary menuType text-white mb-1 align-self-stretch"}>
                        {r.name}
                    </button>
                ))}
                {/* <button key={1} onClick={() => props.chooseRestaurant(1)}
                    className={"btn btn-primary menuType text-white mb-1 align-self-stretch"}>
                    Leyi Sushi
                </button>
                <button key={2} onClick={() => props.chooseRestaurant(2)}
                    className={"btn btn-primary menuType text-white mb-1 align-self-stretch"}>
                    Ristorante prova
                </button> */}
            </Modal.Body>
        </Modal>
    );
};

export default RestaurantsModal;