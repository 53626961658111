import '../style/MyNavbar.css';
import BottomList from './BottomList';
import MenuList from './MenuList';

function MyNavbar(props) {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark d-sm-none myNavbar">
      <div className="container-fluid">
        <button id="myNavbar-toggler" className="navbar-toggler" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="true" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse mt-2 show" id="navbarNav">
          <MenuList key={'menulist-myNavbar'} isNavbar={true} {...props} />
          <BottomList {...props} setIsContactUsModalOpen={props.setIsContactUsModalOpen}/>
        </div>
      </div>
    </nav>
  );
}
export default MyNavbar;