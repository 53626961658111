import '../style/MenuTypeModal.css';
import React, { useState, useEffect } from 'react';
/** Bootstrap */
import { Modal, Button } from 'react-bootstrap';
import MenuType from '../MenuType';
/** Services */
import { createMenuFetch } from '../../services/api';

import { useTranslation } from 'react-i18next';

import { fullError, showDangerToast, showSuccessToast } from '../../services/util';

const MenuTypeModal = (props) => {
    const { t } = useTranslation();

    const [newMenuTypeName, setNewMenuTypeName] = useState('');

    useEffect(() => {
        if (!props.setMenuTypeSelected) props.setMenuTypeSelected(false)

    }, [props.setMenuTypeSelected]);

    const addNewMenuType = async () => {

        if (!props.menuTypeList.some(m => (m.name.toLowerCase().trim() === newMenuTypeName.toLowerCase().trim()))) {
            if (newMenuTypeName !== '') {
                try {
                    props.setShowLoading(true);
                    const menuFetch = await createMenuFetch(newMenuTypeName, 0);
                    if (menuFetch.result === 'OK') {
                        var newMenyTypeObj = {
                            _id: menuFetch.data.menuId,
                            name: newMenuTypeName,
                            starting_price: 0
                        }
                        props.setMenuTypeList(prevList => {
                            var tempList = [...prevList];
                            tempList.push(newMenyTypeObj);
                            return tempList;
                        });
                        setNewMenuTypeName('');
                        showSuccessToast(t('menuAddedCorrectly'));
                    } else {
                        fullError(t('anErrorOccurredDuringMenuAddition'), menuFetch);
                    }
                } catch (error) {
                    fullError(t('anErrorOccurredDuringMenuAddition'), error);
                } finally {
                    props.setShowLoading(false);
                }
            } else {
                showDangerToast(t('enterMenuName'));
            }
        } else {
            showDangerToast(t('menuAlreadyExists'));
        }
    };
    const handleKeyUp = (event) => {
        if (event.key === 'Enter') {
            addNewMenuType();
        }
    }
    return (
        <Modal show={props.isModalOpen} onHide={() => props.setIsModalOpen(false)} backdrop="static" keyboard={false} centered>
            <Modal.Header>
                <Modal.Title>{t('chooseTheMenu')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-2">
                {props.menuTypeList.length === 0 && <h5 className="mb-4">Nessun tipo di menù inserito</h5>}
                {props.menuTypeList.map(menuTypeObj => (
                    <MenuType setIsAllowedToClose={props.setIsAllowedToClose} setFirstTimeSelected={props.setFirstTimeSelected} key={"menuTypeElem-" + menuTypeObj._id}
                        setShowLoading={props.setShowLoading} showLoading={props.showLoading} isStaff={props.isStaff} isAdmin={props.isAdmin} menuType={props.menuType}
                        menuTypeObj={menuTypeObj} menuTypeList={props.menuTypeList} setMenuTypeList={props.setMenuTypeList} chooseTypeMenu={props.chooseTypeMenu} />
                ))}
                {props.isAdmin && !props.isStaff &&
                    <div className={"input-group"}>
                        <input value={newMenuTypeName} onChange={(event) => setNewMenuTypeName(event.target.value)} onKeyUp={handleKeyUp}
                            type="text" id="newMenuTypeName" className="form-control" placeholder={t('newTypeOfMenu')} disabled={props.showLoading} />
                        <button onClick={() => { addNewMenuType() }} type="button" className="btn btn-primary changeMenu menuType bi bi-check" disabled={props.showLoading}>
                        </button>
                    </div>
                }
            </Modal.Body>
            {(!!props.menuTypeSelected && props.isAllowedToClose) &&
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.setIsModalOpen(false)}>
                        {t('close')}
                    </Button>
                </Modal.Footer>
            }
        </Modal>
    );
};

export default MenuTypeModal;