import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StatusPage = () => {
    const { t, i18n } = useTranslation();

    let query = new URLSearchParams(useLocation().search);
    let deletionStatus = query.get('deletionStatus');

    return (
        <div>
            {deletionStatus === 'NotFound'
                ? <h1>{t('DeletionRequestNotFoundStatus')}</h1>
                : <h1>{t('DeletionRequestStatus')} {i18n.exists('DeletionRequest' + deletionStatus + 'Status') ? t('DeletionRequest' + deletionStatus + 'Status'): ''}</h1>
            }
        </div>
    );
}

export default StatusPage;