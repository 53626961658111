import React from 'react';

function CartRow(props) {
    return (
        <div key={props.cart.meal.id} className="row cart">
            <div className="column col-4 d-flex align-items-center pe-1"><img src={props.cart.meal.plate_data.photo} /></div>
            <div className="column col-6 pb-2 pt-1 ps-1 ps-sm-3 sx">
                <strong className="text-nowrap">{props.cart.meal.plate_data.name}</strong>
                <div className="quantityBox d-flex align-items-center">
                    <div className="quantity">
                        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                            <div className="w-100 btn-group col col-12 col-sm-6 quantityButton">
                                {props.editable !== false &&
                                    <button onClick={() => props.changeQuantityToCart(props.cart.meal, -1)} type="button" className="btn btn-primary change">
                                        <i className="bi bi-dash"></i>
                                    </button>
                                }
                                <div className={"quantity d-flex align-items-center" + (props.editable !== false ? " justify-content-center" : "")} name="quantity">
                                    {props.editable !== false ?

                                        props.cart.quantity

                                        :
                                        <h4>
                                            {props.cart.quantity}
                                        </h4>
                                    }

                                </div>
                                {props.editable !== false &&
                                    <button onClick={() => props.changeQuantityToCart(props.cart.meal, +1)} type="button" className="btn btn-primary change">
                                        <i className="bi bi-plus"></i>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column col-2 d-flex flex-column align-items-end justify-content-evenly rx">
                {props.editable !== false &&
                    <span className="price">{(props.cart.meal.price * props.cart.quantity)} €</span>
                }
            </div>
        </div>
    );
}
export default CartRow;