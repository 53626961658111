
import sound from '../../assets/bell.wav';

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { getOrderIdByLongId } from '../../services/util';

/** Services */
import { fetchOrderList, confirmOrderFetch, confirmMealsFetch, deleteMealsFetch, getRestaurant } from '../../services/api';

import { fullError, showSuccessToast, fetchPostProcess, listenMessage } from '../../services/util';

function OrderList(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    if (!props.isAdmin) navigate('/');

    const [orders, setOrders] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState({});
    const [showConfirmButtons, setShowConfirmButtons] = useState({});
    const [componentReady, setComponentReady] = useState(false);

    useEffect(() => {
        if (componentReady === false) {
            setComponentReady(() => {
                return true;
            });
        }
    }, []);
    useEffect(() => {
        if (componentReady && (orders.length === 0)) {
            fetchData();
        }
    }, [componentReady]);

    /** API */
    const fetchData = async () => {
        props.setShowLoading(true);
        try {
            const orderList = await fetchOrderList();
            var tempOrderList = [];
            orderList.data.forEach(o => {
                tempOrderList[o.session_id + '-' + o._id] = o;
            });

            setOrders(tempOrderList);
        } catch (error) {
        } finally {
            props.setShowLoading(false);
        }
    };

    useEffect(() => {
        props.setPageTo('orderList');
        listenMessage(async () => {
            fetchData();
            playSound();
        }, 'order_sent_' + getRestaurant(), 'orderSent', false);
    }, []);


    useEffect(() => {
        if (Object.values(selectedOrders).filter(so => so === true).length > 0) {
            setShowConfirmButtons(true);
        } else {
            setShowConfirmButtons(false);
        }
    }, [selectedOrders]);


    const playSound = () => {        
        new Audio(sound).play();
    }
    const confirmSelectedQuestion = async () => {
        if (await window.confirm(t('confirmSelected'))) {
            confirmSelected();
        }
    }
    const deleteSelectedQuestion = async () => {
        if (await window.confirm(t('confirmDeletion'))) {
            deleteSelected();
        }
    }
    const deleteMeal = async (meal, key) => {
        if (await window.confirm(t('confirmDeletion'))) {
            const orderId = getOrderIdByLongId(key);
            fetchPostProcess(
                () => deleteMealsFetch(orderId, [meal.id]),
                fetchData,
                t('mealCancelledCorrectly'),
                t('anErrorOccurredDuringMealDeletion'),
                props
            );
        }
    }
    const confirmOrder = async (orderKey) => {
        if (await window.confirm(t('confirmWholeOrder'))) {
            fetchPostProcess(
                () => confirmOrderFetch(getOrderIdByLongId(orderKey)),
                fetchData,
                t('orderConfirmedCorrectly'),
                t('anErrorOccurredDuringOrderConfirmation'),
                props
            );
        }
    }

    const confirmMeal = async (meal, key) => {
        if (await window.confirm(t('confirmAction'))) {
            const orderId = getOrderIdByLongId(key);
            fetchPostProcess(
                () => confirmMealsFetch(orderId, [meal.id]),
                fetchData,
                t('mealConfirmedCorrectly'),
                t('anErrorOccurredDuringMealConfirmation'),
                props
            );
        }
    }

    const confirmSelected = async () => {
        var arrayForConfirmMealProcess = [];
        var arrayForConfirmMealsFetch = [];

        Object.entries(selectedOrders).forEach(([objKey, value]) => {

            const key = objKey.split('***')[0];
            objKey = objKey.split('***')[1];

            if (value === true) {
                arrayForConfirmMealProcess.push({
                    meal: { id: objKey },
                    longId: key
                });
                if (!arrayForConfirmMealsFetch[key]) {
                    arrayForConfirmMealsFetch[key] = [];
                }
                arrayForConfirmMealsFetch[key].push(objKey);
            }
        });

        try {
            props.setShowLoading(true);
            var promises = Object.keys(arrayForConfirmMealsFetch).map(async function (longId) {
                const orderId = getOrderIdByLongId(longId);
                const mealsFetch = await confirmMealsFetch(orderId, arrayForConfirmMealsFetch[longId]);
                return mealsFetch.result === 'OK';
            });

            Promise.all(promises).then(resultArray => {
                if (!resultArray.includes(false)) {
                    fetchData();
                    setSelectedOrders([]);
                    showSuccessToast(t('mealsConfirmedSuccessfully'));

                } else {
                    fullError(t('anErrorOccurredDuringSelectedOrdersConfirmation'));
                }
            });

        } catch (error) {
            fullError(t('anErrorOccurredDuringSelectedOrdersConfirmation'), error);
        } finally {
            props.setShowLoading(false);
        }
    }

    const deleteSelected = async () => {
        var arrayForDeleteMealProcess = [];
        var arrayForDeleteMealsFetch = [];

        Object.entries(selectedOrders).forEach(([objKey, value]) => {

            const key = objKey.split('***')[0];
            objKey = objKey.split('***')[1];

            if (value === true) {
                arrayForDeleteMealProcess.push({
                    meal: { id: objKey },
                    longId: key
                });
                if (!arrayForDeleteMealsFetch[key]) {
                    arrayForDeleteMealsFetch[key] = [];
                }
                arrayForDeleteMealsFetch[key].push(objKey);
            }
        });
        try {
            props.setShowLoading(true);

            var promises = Object.keys(arrayForDeleteMealsFetch).map(async function (longId) {
                const orderId = getOrderIdByLongId(longId);
                const mealsFetch = await deleteMealsFetch(orderId, arrayForDeleteMealsFetch[longId]);
                return mealsFetch.result === 'OK';
            });
            Promise.all(promises).then(resultArray => {

                if (!resultArray.includes(false)) {
                    fetchData();
                    setSelectedOrders([]);
                    showSuccessToast(t('ordersCancelledSuccessfully'));
                } else {
                    fullError(t('anErrorOccurredDuringSelectedMealsDeletion'));
                }
            });

        } catch (error) {
            fullError(t('anErrorOccurredDuringSelectedMealsDeletion'), error);
        } finally {
            props.setShowLoading(false);
        }
    }
    const handleCheckboxChange = (mealId) => {
        setSelectedOrders(prevState => ({
            ...prevState,
            [mealId]: !prevState[mealId]
        }));
    }
    const getHour = (timestamp) => {
        const dateString = timestamp;
        const dateObject = new Date(dateString);

        const formattedTime = new Intl.DateTimeFormat('it-IT', {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZone: 'Europe/Rome', // Fuso orario dell'Italia
        }).format(dateObject);

        return formattedTime;
    };
    return (
        <div className="container list">
            <a id="admin-section" className="adminSection"></a>
            {Object.keys(orders).length === 0 &&
                <div className="text-center">
                    <h4>
                        {t('TheListIsEmpty')}
                    </h4>
                    <button onClick={fetchData} type="button" className="btn btn-primary updateList mt-2" disabled="">{t('update')}</button>
                </div>

            }
            {
                Object.keys(orders).map((key) => (
                    <fieldset key={key}>
                        <legend><strong>{t('table') + ' ' + orders[key].table.number}</strong>{' - ' + getHour(orders[key].order_placed_at)}</legend> {
                            Array.isArray(orders[key].plates) && orders[key].plates.map(meal => (
                                <div key={key + '-' + meal.id} className="row my-2 py-2">
                                    <div className="column col-4 d-flex align-items-center pe-1"><img src={meal.plate_data.photo} /></div>
                                    <div className="column col-6 pb-2">
                                        <h6 className="fw-bold mealTitle">{meal.plate_data.name}</h6>
                                        <div className="quantityBox d-flex flex-column">
                                            <h4>{meal.amount}</h4>
                                            <div className="singleConfirm">
                                                <button onClick={() => { confirmMeal(meal, key) }} type="button"
                                                    className="btn btn-success w-100">
                                                    {t('ready')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column  col-2 d-flex flex-column align-items-end justify-content-evenly rx">
                                        <div className="form-check">
                                            <input className="checkMeal" type="checkbox" value="" checked={selectedOrders[key + '***' + meal.id] || false} onChange={() => handleCheckboxChange(key + '***' + meal.id)} />
                                        </div>
                                        <button onClick={() => deleteMeal(meal, key)} type="button"
                                            className="btn btn-danger quantity">
                                            <i className="bi bi-trash-fill"></i>
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                        <div className="confirmBox d-flex justify-content-center">
                            <button onClick={() => confirmOrder(key)} type="button"
                                className="btn btn-primary me-1 singleOrderConfirm">
                                {t('orderReady')}
                            </button>
                        </div>
                    </fieldset>
                ))
            }
            {
                <div className="position-fixed ordinaContainer orderListBottomMenu py-1">
                    <div className="d-flex w-75 justify-content-center justify-content-sm-end">
                        <button onClick={() => confirmSelectedQuestion()} type="button" className="btn btn-primary me-1" disabled={showConfirmButtons ? false : true}>
                            {t('confirmSelected')}
                        </button>
                        <button onClick={() => deleteSelectedQuestion()} type="button" className="btn btn-danger" disabled={showConfirmButtons ? false : true}>
                            {t('deleteSelected')}
                        </button>
                    </div>
                </div>
            }
        </div>
    );
}
export default OrderList;