
import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { logout } from '../../services/util';

import { useTranslation } from 'react-i18next';


const BottomList = (props) => {
  const { t } = useTranslation();
  const logoutProcess = () => {
    props.setShowLoading(true);
    logout();
  }
  return (
    <ul className="bottomList nav nav-pills text-start">
      {(!props.isAssigned && props.isAdmin === false) &&
        <li className="nav-item w-100">
          <Link onClick={() => { props.setSelected('qrCode') }} className={"nav-link p-2 ps-3 " + (props.selected == 'qrCode' ? 'active' : '')} to="qr-code"><i className="bi bi-qr-code me-2"></i> QR Code</Link>
        </li>
      }
      {props.isAdmin === true && !props.isStaff &&
        <li className="nav-item w-100"><Link className={"nav-link p-1 ps-3 " + (props.selected == 'staff' ? 'active' : '')} to="staff"><i className="bi bi-people me-2"></i>{t('staff')}</Link></li>
      }
      <li className="nav-item w-100"><Link className={"nav-link p-1 ps-3 " + (props.selected == 'settings' ? 'active' : '')} onClick={() => { props.setSelected('settings') }} to="settings#settingsBox"><i className="bi bi-gear me-2"></i>Impostazioni</Link></li>
      <li className="nav-item w-100"><a className="nav-link" onClick={() => props.setIsContactUsModalOpen(true)}><i className="bi bi-envelope-arrow-up-fill me-2"></i>{t('contactUs')}</a></li>

      <li className="nav-item w-100"><a className="nav-link" onClick={() => logoutProcess()}><i className="bi bi-box-arrow-right me-2"></i>{t('logout')}</a></li>

    </ul>
  );
};

export default BottomList;