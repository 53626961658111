import React, {useCallback, useEffect} from "react";
import Cookies from 'js-cookie';
import {fetchLoginFacebook} from "../../../services/api";
import {fullError} from "../../../services/util";
import {useTranslation} from "react-i18next";
import LoginForm from "../../form/LoginForm";
import {GoogleOAuthProvider} from "@react-oauth/google";

const FacebookLoginCallback = (props) => {
    const { t } = useTranslation();

    const completeFacebookLogin = useCallback(async () => {

        const cookieValue = Cookies.get('fbtoken');

        try {
            if (!cookieValue) {
                throw new Error(t('CookieTokenNotValid'));
            }

            const result = await fetchLoginFacebook(cookieValue);
            props.loginProcess(result);
        } catch (error) {
            console.log("anErrorOccurred");
            fullError(t('anErrorOccurred'), error);
        }

    }, []);

    useEffect(() => {
        props.setShowLoading(true);

        completeFacebookLogin().then(() => {props.setShowLoading(false)});


    }, [completeFacebookLogin]);

    return (<GoogleOAuthProvider
            clientId={props.clientId}
        >
            <LoginForm loginProcess={props.loginProcess} setShowLoading={props.setShowLoading} />
        </GoogleOAuthProvider>
    );
}

export default FacebookLoginCallback;