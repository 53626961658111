import '../style/Cart.css';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import CartRow from './cart/CartRow';
import { fetchOrderListPrev } from '../../services/api';
import { getHour } from '../../services/util';

const getSessionId = () => Cookies.get('sessionId');

function Cart(props) {

    const [componentReady, setComponentReady] = useState(false);
    const [prevOrders, setPrevOrders] = useState([]);

    useEffect(() => {
        if (componentReady === false) {
            setComponentReady(() => {
                return true;
            });
        }
    }, []);

    useEffect(() => {
        if (componentReady && (prevOrders.length === 0)) {
            fetchPrevOrderData();
        }
    }, [componentReady]);

    const fetchPrevOrderData = async () => {
        props.setShowLoading(true);
        try {
            const orderList = await fetchOrderListPrev(getSessionId());
            var tempOrderList = [];
            orderList.data.forEach(o => {
                const tempOrderId = o.session_id + '-' + o._id;
                tempOrderList[tempOrderId] = [];
                o.plates.forEach(p => {
                    tempOrderList[tempOrderId].push({
                        ...o,
                        idForKey: o._id + '**' + p.id,
                        quantity: p.amount,
                        meal: p
                    });
                });
            });
            setPrevOrders(tempOrderList);
        } catch (error) {
            // Gestisci l'errore secondo le tue esigenze
        } finally {
            props.setShowLoading(false);
        }
    };


    useEffect(() => {
        props.setPageTo('cart');
    }, [props.setPageTo]);
    return (
        <>
            <div>
                <div className="container list">
                    {props.cart.length === 0 &&
                        <div className="emptyCartBox">
                            <h4>
                                Il carrello è vuoto
                            </h4>
                        </div>
                    }
                    {props.cart.map((c) => (<CartRow key={c._id} changeQuantityToCart={props.changeQuantityToCart} cart={c} />))}
                    {Object.keys(prevOrders).length > 0 &&
                        <>
                            <h4 className="mt-3">Hai ordinato in precedenza:</h4>
                            <hr />
                        </>
                    }

                    {Object.keys(prevOrders).map((key) => (
                        <div>
                            <h5 className="mt-3">{getHour(prevOrders[key][0].order_placed_at)}</h5>
                            {prevOrders[key].map((po) => (<CartRow key={po.idForKey} editable={false} cart={po} />))}
                        </div>
                    ))}
                    {/* {prevOrders.map((po) => (<CartRow editable={false} cart={po} />))} */}
                </div>
            </div>
            {/* {props.cart.length > 0 ?  */}
            <div className="cartBottomBox">

                <div className="totalBox">
                    {!!props.menuType && props.menuType.starting_price > 0 && props.cart.length > 0 &&
                        <div className="total d-flex justify-content-center align-items-center">
                            Prezzo Fisso: <span className="price ms-1">{props.menuType.starting_price} €</span>
                        </div>
                    }
                    {props.cart.length > 0 &&
                        <div className="total d-flex justify-content-center align-items-center">
                            Totale: <span className="price ms-1">{
                                props.cart.reduce((acc, cObj) => acc + cObj.meal.price * cObj.quantity, 0)
                                + (!!props.menuType && props.menuType.starting_price)} €</span>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}
export default Cart;