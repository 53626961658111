
import React, { useEffect, useState } from 'react';
import EditMeal from './EditMeal';

import { useTranslation } from 'react-i18next';

import { fullError, showSuccessToast } from '../services/util';

/** SERVICES */
import { deleteMealFetch } from '../services/api';


function Meal(props) {

    const { t } = useTranslation();
    const [quantity, setQuantity] = useState(() => {
        // Tenta di ottenere la quantità dal carrello al momento del caricamento iniziale
        const matchingCartItem = props.cart.find(item => item.meal.id === props.id);
        return matchingCartItem ? matchingCartItem.quantity : 0;
    });
    var [isEdit, setIsEdit] = useState(false);

    const backgroundColor = quantity > 0 ? '#FF7F7F' : 'white';
    const quantityStyle = (quantity > 0) ? {
        fontWeight: 'bold',
        backgroundColor: 'white'
    } : {
        color: 'black'
    };

    useEffect(() => {
        const matchingCartItem = props.cart.find((item) => item.meal.id === props.id);
        setQuantity(matchingCartItem ? matchingCartItem.quantity : 0);
    }, [props.cart, props.id]);

    const incrementMeals = () => {
        setQuantity(prevQuantity => prevQuantity + 1);
        props.changeQuantityToCart(props, +1);
    }
    const decreaseMeals = () => {
        if (quantity > 0) {
            setQuantity(prevQuantity => prevQuantity - 1);
            props.changeQuantityToCart(props, -1);
        }
    }
    const deleteMeal = async (meal) => {
        if (await window.confirm(t('deleteTheSelectedMeal'))) {
            try {
                props.setShowLoading(true);

                const result = await deleteMealFetch(meal.id);

                if (result.result === 'OK') {
                    props.setShowLoading(false);
                    props.fetchMenuTypeData(props.menuType);
                } else {
                    fullError(t('anErrorOccurredDuringMealDeletion'), result);
                }

                showSuccessToast(t('mealCancelledCorrectly'));
            } catch (error) {
                fullError(t('anErrorOccurredDuringMealDeletion'), error);
            } finally {
                props.setShowLoading(false);
            }
        }
    }
    const setIsEditEvent = async () => {
        setIsEdit(true);
        props.setIsEditGlobal(true);
    }
    return (
        <div className="col">
            {!isEdit ?
                <div className={"card shadow-sm " + (props.isEditGlobal === true ? 'disabled' : '')}>
                    <h3 className="title">{props.plate_data.name}</h3>
                    <div className="card-body ingredients">{props.plate_data.ingredients}</div>
                    <div className="card-body details d-flex">
                        <span className="price">{props.price} €</span>
                        {/* <img className="type" src="/img/svg/fish.svg" /><img className="type" src="img/svg/meat.svg" /><img className="type" src="img/svg/gluten-free.svg" /> */}
                    </div>
                    <img src={props.plate_data.photo} className="foodImg" />
                    {(props.isAdmin && !props.isStaff) ?
                        <div className={"card-body buttonBox " + (props.isEditGlobal === true ? 'disabled' : '')}>
                            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                                <div className="btn-group mb-2 mb-sm-0 col col-12 col-sm-6 quantityButton">
                                    <button onClick={() => setIsEditEvent()} type="button" className="btn btn-warning change d-flex
                                     justify-content-center align-items-center w-75" disabled={props.showLoading}>
                                        <i onClick={() => setIsEditEvent()} className="bi bi-pencil-square me-2"></i> {t('edit')}
                                    </button>
                                    <button onClick={async () => await deleteMeal(props)} type="button"
                                        className="btn btn-danger me-1 bi bi-trash deleteCustomer w-25" disabled={props.showLoading}>
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="card-body buttonBox" style={{ backgroundColor: backgroundColor }}>
                            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                                <div style={{ 'visibility': props.isAdmin ? 'hidden' : '' }} className="btn-group mb-2 mb-sm-0 col col-12 col-sm-6 quantityButton">
                                    <button onClick={decreaseMeals} type="button" className="btn btn-primary change">
                                        <i className="bi bi-dash"></i>
                                    </button>
                                    <div style={quantityStyle} className="quantity" name="quantity">
                                        {quantity}
                                    </div>
                                    <button onClick={incrementMeals} type="button" className="btn btn-primary change">
                                        <i className="bi bi-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                    }
                </div>
                :
                <EditMeal  isStaff={props.isStaff} setIsEditGlobal={props.setIsEditGlobal} fetchMenuTypeData={props.fetchMenuTypeData}
                    setShowLoading={props.setShowLoading} showLoading={props.showLoading} setIsEdit={setIsEdit}
                    isAdmin={props.isAdmin} menuType={props.menuType} selectedType={props.selectedType} setMeals={props.setMeals} meals={props.meals} meal={props} />
            }
        </div>
    );
}
export default Meal;