import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { updateCategoryFetch, deleteCategoryFetch } from '../../services/api';

import { fetchPostProcess } from '../../services/util';

function MenuElem(props) {
    const { t } = useTranslation();

    const [isEditME, setIsEditME] = useState(false);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (props.showLoading === false) setInputValue(props.mealType.name.charAt(0).toUpperCase() + props.mealType.name.slice(1));
    }, [props.mealType.name, props.showLoading])
    const handleInputChangeME = (event) => {
        const newValue = event.target.value;
        const modifiedValue = newValue.charAt(0).toUpperCase() + newValue.slice(1);
        setInputValue(modifiedValue);
    };
    const handleKeyUpEditCategory = (event) => {
        if (event.key === 'Enter') {
            editCategory(event.target.value);
        }
    }
    const editCategory = async () => {
        fetchPostProcess(
            () => updateCategoryFetch(props.menuTypeSelected._id, props.mealType.id, inputValue),
            () => props.fetchMenuTypeData(props.menuTypeSelected),
            t('correctlyEditedCategory'),
            t('anErrorOccurredDuringCategoryModification'),
            props
        );
        setIsEditME(false);
    };
    const deleteCategory = async () => {
        if (await window.confirm(t('confirmDeleteSelectedCategory'))) {
            fetchPostProcess(
                () => deleteCategoryFetch(props.menuTypeSelected._id, props.mealType.id),
                () => {
                    props.fetchMenuTypeData(props.menuTypeSelected);
                    props.setSelectedType(false);
                },
                t('categoryDeletedCorrectly'),
                t('anErrorOccurredDuringCategoryDeletion'),
                props
            );
        }
    };
    return (
        <li key={props.mealType.name} className={'editCategoryBox nav-item d-flex align-items-center text-center ' + (props.isAdmin ? 'flex-column justify-content-center' : '')}>
            {isEditME === false ? (
                <a href={(props.isNavbar) ? "#meal-list" : undefined} onClick={() => props.changeMeals(props.mealType.id)}
                    className={"w-100 nav-link text-white text-capitalize " + ((props.mealType.id == props.selected) ? 'active' : '')} aria-current="page">
                    {props.mealType.name}
                </a>
            ) : (<div className="d-flex align-items-center flex-column text-center justify-content-center editBox">
                <input value={inputValue} onKeyUp={(event) => handleKeyUpEditCategory(event)}
                    onChange={(event) => handleInputChangeME(event)} type="text" className="form-control edit" />
                <button onClick={() => editCategory()} className="btn btn-primary d-flex justify-content-center align-items-center w-100">
                    Conferma
                </button>
            </div>)}
            {/** ADMIN SECTION */
                props.isAdmin && ! props.isStaff && !isEditME &&
                <div className="d-flex align-items-center justify-content-center w-100">
                    <div className="btn-group w-100 col col-12 col-sm-6 quantityButton">
                        <button onClick={() => { setIsEditME(true) }} type="button" className="btn btn-warning change d-flex
                                     justify-content-center align-items-center w-75" disabled={props.showLoading}>
                            <i onClick={() => { setIsEditME(true) }} className="bi bi-pencil-square me-2"></i>
                        </button>
                        <button onClick={deleteCategory} type="button"
                            className="btn btn-danger bi bi-trash deleteCustomer" disabled={props.showLoading}>
                        </button>
                    </div>
                </div>
            }
        </li>
    );
}
export default MenuElem;