import React, {useCallback, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {fetchConfirmEmail} from "../../../services/api"; // Assumendo che tu abbia questa funzione
import {fullError} from "../../../services/util";
import {loginProcess} from "../../../services/login";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../loader";

const ConfirmEmailPage = ({setIsRestaurantsModalOpen, setIsEmailToVerify, setIsLoggedIn, isLoggedIn}) => {
    const { t } = useTranslation();
    const { token } = useParams();

    const [showError, setShowError] = useState(false);
    const [showLoading, setShowLoading] = useState(true);

    const navigate = useNavigate();

    const completeEmailConfirmation = useCallback(async () => {
        setShowError(false);
        try {
            if (!token) {
                throw new Error(t('ConfirmEmailLinkNotValid'));
            }

            const result = await fetchConfirmEmail(token);
            if(!isLoggedIn)
            {
                loginProcess(result, setIsRestaurantsModalOpen, setIsEmailToVerify, setIsLoggedIn, setShowError);
            }
            navigate("/");
        } catch (error) {
            if (error.response.data.errorType !== 'invalidToken') {
                console.log("anErrorOccurred");
                fullError(t('anErrorOccurred'), error);
                setShowError(true);
            }
        }

    }, [token]);

    useEffect(() => {
        setShowError(false);
        completeEmailConfirmation().then(() => {setShowLoading(false)});
    }, [completeEmailConfirmation]);

    return (
        <>
        <LoadingComponent showLoading={showLoading} />
            {!showLoading && (
                <div className="text-center">
                    {showError ? (
                        <h1>{t('anErrorOccurred')}</h1>
                    ) : (
                        <>
                            <h1>{t('VerificationLinkNotValidOrExpired')}</h1>
                            <p>{t('MaybeEmailAlreadyConfirmed')}</p>
                        </>
                    )}
                    <button className="w-100 btn btn-lg btn-primary mb-3" type="button" onClick={() => navigate("/")}>
                        {t('GoBackToHome')}
                    </button>
                </div>
            )}
        </>
    );
}

export default ConfirmEmailPage;