import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { getOrderIdByLongId, fetchPostProcess } from '../../services/util';

/** Services */
import { fetchReadyOrders, completeOrderFetch, completeMealsFetch } from '../../services/api';

import { fullError, showSuccessToast } from '../../services/util';

function ReadyOrders(props) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    if (!props.isAdmin) navigate('/');

    var [orders, setOrders] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState({});
    const [showConfirmButtons, setShowConfirmButtons] = useState({});
    const [componentReady, setComponentReady] = useState(false);

    useEffect(() => {
        if (componentReady === false) {
            setComponentReady(() => {
                return true;
            });
        }
    }, []);

    useEffect(() => {
        if (componentReady && (orders.length === 0)) {
            fetchData();
        }
    }, [componentReady]);

    const fetchData = async () => {
        props.setShowLoading(true);
        try {
            const orderList = await fetchReadyOrders();
            var tempOrderList = [];
            orderList.data.forEach(o => {
                tempOrderList[o.session_id + '-' + o._id] = o;
            });
            setOrders(tempOrderList);
            props.setPageTo('readyOrders');
        } catch (error) {
            // Gestisci l'errore secondo le tue esigenze
        } finally {
            props.setShowLoading(false);
        }
    };


    useEffect(() => {
        if (Object.values(selectedOrders).filter(so => so === true).length > 0) {
            setShowConfirmButtons(true);
        } else {
            setShowConfirmButtons(false);
        }
    }, [selectedOrders]);


    const completeSelectedQuestion = async () => {
        if (await window.confirm(t('completeSelected'))) {
            completeSelected();
        }
    }
    const completeMeal = async (meal, key) => {
        if (await window.confirm(t('confirmAction'))) {
            const orderId = getOrderIdByLongId(key);
            fetchPostProcess(
                () => completeMealsFetch(orderId, [meal.id]),
                fetchData,
                t('mealConfirmedCorrectly'),
                t('anErrorOccurredDuringSaveChanges'),
                props
            );
        }
    }
    const completeOrder = async (orderKey) => {
        if (await window.confirm(t('confirmWholeOrder'))) {
            fetchPostProcess(
                () => completeOrderFetch(getOrderIdByLongId(orderKey)),
                fetchData,
                t('orderConfirmedCorrectly'),
                t('anErrorOccurredDuringOrderCompletation'),
                props
            );

        }
    }
    const completeSelected = async () => {
        var arrayForCompleteMealProcess = [];
        var arrayForCompleteMealsFetch = [];

        Object.entries(selectedOrders).forEach(([objKey, value]) => {

            const key = objKey.split('***')[0];
            objKey = objKey.split('***')[1];

            if (value === true) {
                arrayForCompleteMealProcess.push({
                    meal: { id: objKey },
                    longId: key
                });
                if (!arrayForCompleteMealsFetch[key]) {
                    arrayForCompleteMealsFetch[key] = [];
                }
                arrayForCompleteMealsFetch[key].push(objKey);
            }
        });

        try {
            props.setShowLoading(true);
            var promises = Object.keys(arrayForCompleteMealsFetch).map(async function (longId) {
                const orderId = getOrderIdByLongId(longId);
                const mealsFetch = await completeMealsFetch(orderId, arrayForCompleteMealsFetch[longId]);
                return mealsFetch.result === 'OK';
            });

            Promise.all(promises).then(resultArray => {
                if (!resultArray.includes(false)) {
                    fetchData();
                    setSelectedOrders([]);
                    showSuccessToast(t('mealsCompletedSuccessfully'));
                } else {
                    fullError(t('anErrorOccurredDuringSelectedOrdersCompletation'));
                }
            });

        } catch (error) {
            fullError(t('anErrorOccurredDuringSelectedOrdersCompletation'), error);
        } finally {
            props.setShowLoading(false);
        }
    }
    const handleCheckboxChange = (mealId) => {
        setSelectedOrders(prevState => ({
            ...prevState,
            [mealId]: !prevState[mealId]
        }));
    }
    const getHour = (timestamp) => {
        const dateString = timestamp;
        const dateObject = new Date(dateString);

        const formattedTime = new Intl.DateTimeFormat('it-IT', {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZone: 'Europe/Rome', // Fuso orario dell'Italia
        }).format(dateObject);

        return formattedTime;
    };
    const checkIfAnyPlateNotReady = (plates) =>  {
        // Itera su ciascun piatto nell'array
        for (let i = 0; i < plates.length; i++) {
          // Se il piatto non ha la proprietà "ready" oppure "ready" è impostato su false, restituisci true
          if (!plates[i].ready) {
            return true;
          }
        }
        // Se tutti i piatti sono pronti, restituisci false
        return false;
      }
    return (
        <div className="container list">
            <a key={"ready-orders-anchor-empty-list"} id="admin-section" className="adminSection"></a>
            {Object.keys(orders).length === 0 ?
                <div className="text-center">
                    <h4>
                        {t('TheListIsEmpty')}
                    </h4>
                    <button onClick={fetchData} type="button" className="btn btn-primary updateList mt-2" disabled="">{t('update')}</button>
                </div>
                :

                Object.keys(orders).map((key) => (
                    <fieldset key={key}>
                        <legend><strong>{t('table') + ' ' + orders[key].table.number}</strong>{' - ' + getHour(orders[key].order_placed_at)}</legend>  {
                            Array.isArray(orders[key].plates) && orders[key].plates.map(meal => (

                                <div key={key + '-' + meal.id} className={"row my-2 py-2 " + ((!!meal.ready && meal.ready === true) ? "" : "disabled")}>
                                    <div className="column col-4 d-flex align-items-center pe-1"><img src={meal.plate_data.photo} /></div>
                                    <div className="column col-6 ps-2 ps-sm-3 pb-2 pe-0">
                                        <h6 className="fw-bold mealTitle">{meal.plate_data.name}</h6>
                                        <div className="quantityBox d-flex flex-column">
                                            <h4>{meal.amount}</h4>
                                            <div className="singleConfirm">
                                                <button onClick={() => { completeMeal(meal, key) }} type="button"
                                                    className="btn btn-success w-100">
                                                    {t('completed')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column  col-2 d-flex flex-column align-items-end justify-content-evenly rx">
                                        <div className="form-check">
                                            <input className="checkMeal" type="checkbox" value="" checked={selectedOrders[key + '***' + meal.id] || false} onChange={() => handleCheckboxChange(key + '***' + meal.id)} />
                                        </div>
                                    </div>
                                </div>

                            ))
                        }
                        <div className="confirmBox d-flex">
                            <button onClick={() => completeOrder(key)} type="button"
                                className={"btn btn-primary w-100 singleOrderConfirm " + (checkIfAnyPlateNotReady(orders[key].plates) ? "disabled" : "") }>
                                {t('orderCompleted')}
                            </button>
                        </div>
                    </fieldset>
                ))
            }
            {
                <div className="position-fixed ordinaContainer orderListBottomMenu py-1">
                    <div className="d-flex w-75 justify-content-center">
                        <button onClick={() => completeSelectedQuestion()} type="button" className="btn btn-primary me-1" disabled={showConfirmButtons ? false : true}>
                            {t('completeSelected')}
                        </button>
                    </div>
                </div>
            }
        </div>
    );
}
export default ReadyOrders;