import '../style/QrCodeScannerModal.css';
import React, { useRef, useEffect } from 'react';
/** Bootstrap */
import { Modal, Button } from 'react-bootstrap';/** Services */

import { Html5QrcodeScanner, Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode";

import { useTranslation } from 'react-i18next';

const QrCodeScannerModal = (props) => {
    const { t } = useTranslation();

    const html5QrcodeScannerRef = useRef(null);

    useEffect(() => {
        if (props.isModalOpen) scanQRCode(props.obj);
    }, [props.isModalOpen, props.obj]);


    function onScanFailure(error) {
        console.warn(`Code scan error = ${error}`);
    }
    const scanQRCode = (obj) => {
        
        html5QrcodeScannerRef.current = new Html5Qrcode(
            "qrCodeReader", { formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE] });

        const config = { fps: 10, qrbox: { width: 250, height: 250 } };
        html5QrcodeScannerRef.current.start({ facingMode: "environment" }, config, onScanSuccess);
        async function onScanSuccess(decodedText, decodedResult) {
            if (props.showLoading === false) {
                closeModal();
                props.acceptance(obj, decodedText);
            }
        }
    }
    async function closeModal() {
        await html5QrcodeScannerRef.current.stop();
        html5QrcodeScannerRef.current.clear();
        props.closeModal();
    }
    return (
        <Modal show={props.isModalOpen} onHide={closeModal} centered>
            <Modal.Header>
                <Modal.Title>{t('scanTheQRCode')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-2">
                <div id="qrCodeReader" width="600px"></div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Chiudi
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default QrCodeScannerModal;