import '../style/ContactUsModal.css';
import React, { useState } from 'react';
/** Bootstrap */
import { Modal, Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import { showDangerToast, fetchPostProcess } from '../../services/util';
import { sendEmailFetch } from '../../services/api';

const ContactUsModal = (props) => {
    const { t } = useTranslation();
    const [argumentValue, setArgumentValue] = useState('');
    const [bodyValue, setBodyValue] = useState('');
    const sendMessage = async () => {
        if (argumentValue.trim() !== '' && bodyValue.trim() !== '') {
            if (await window.confirm(t('confirmSendMessage'))) {
                fetchPostProcess(
                    () => sendEmailFetch(argumentValue.trim(), bodyValue.trim()),
                    () => props.setIsContactUsModalOpen(false),
                    t('MessageSentWithSuccess'),
                    t('anErrorOccurredDuringSendMessage'),
                    props
                );
            }

        } else {
            showDangerToast(t('enterAllParameters'));
        }
    }
    return (
        <Modal show={props.isContactUsModalOpen} onHide={() => props.setIsContactUsModalOpen(false)} centered>
            <Modal.Header>
                <Modal.Title>{t('contactUs')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-2">
                <div className="mb-0">
                    <input onChange={(event) => setArgumentValue(event.target.value)} className="form-control" id="oggetto" placeholder="Scrivi l'argomento" />
                </div>
                <div className="mb-3">
                    <label htmlFor="body" className="form-label"></label>
                    <textarea onChange={(event) => setBodyValue(event.target.value)} className="form-control" id="body" rows="10" placeholder="Dacci maggiori dettagli..."></textarea>
                </div>
                <button onClick={sendMessage} type="submit" className="btn btn-primary w-100">Invia</button>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.setIsContactUsModalOpen(false)}>
                    Chiudi
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ContactUsModal;