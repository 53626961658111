import React from 'react';

import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

const MenuAdmin = (props) => {
  const { t } = useTranslation();
  const adminListClick = (element) => {
    props.setSelected(element[1]);
    if (props.isNavbar === true) {
      document.getElementById('myNavbar-toggler').click();
      const elemento = document.getElementById('myNavbar-toggler');
      elemento.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }
  return (
    <>
      { /** ADMIN SECTIONS */
        props.isAdmin && props.adminMenu.map(element => (
          <li key={'admin-list-' + element[0]} className="nav-item d-flex align-items-center flex-column text-center justify-content-center">
            {/* <Link to={element[0].replace(/[A-Z]/g, m => "-" + m.toLowerCase()) + ((props.isNavbar ? "#admin-section" : ""))} onClick={(event) => { adminListClick(element, event) }} */}
            <Link to={element[0].replace(/[A-Z]/g, m => "-" + m.toLowerCase())} onClick={(event) => { adminListClick(element, event) }}
              className={"nav-link text-white w-100 " + (element[1] == props.selected ? 'active' : '')}>
              {element[1]}
            </Link>
          </li>
        )
        )
      }
      { /** ADMIN CREATE CATEGORY */
        props.isAdmin && !props.isStaff && <>
          <button onClick={() => props.setIsModalOpen(true)} type="button" className="btn btn-primary changeMenu my-2 sidebarChangeMenu">
            {t('changeMenu')}
          </button>
        </>
      }
    </>
  );
};

export default MenuAdmin;