import { useState } from "react";
import { channel } from "./broadcastManager";

const useGlobalState = (initialValue) => {
    const [state, setState] = useState(initialValue);

    return [
        state,
        (value) => {
            setState(value); // Funzione `setState` originale
            channel.postMessage({ type: "lazy_reload" }); // Notifica le altre schede
        },
    ];
};

export default useGlobalState;