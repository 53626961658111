
import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { fullError, showDangerToast, showSuccessToast, isNumeric } from '../services/util';

/** Services */
import { editMealFetch, addNewMealFetch } from '../services/api';

const MAX_PRICE = 999;

const EditMeal = (props) => {
    const { t } = useTranslation();
    const [newMealName, setNewMealName] = useState('');
    const [newMealIngredients, setNewMealIngredients] = useState('');
    const [newMealPrice, setNewMealPrice] = useState('');
    const [newMealImage, setNewMealImage] = useState(false);
    const [formDataFile, setFormDataFile] = useState(false);
    useEffect(() => {
        if (!!props.meal && props.showLoading === false) {
            setNewMealName(props.meal.plate_data.name);
            setNewMealIngredients(props.meal.plate_data.ingredients);
            if (!props.isNew) setNewMealPrice(props.meal.price);
            setNewMealImage(props.meal.plate_data.photo);
        }
    }, [props.meals, props.meal, props.isNew, props.showLoading]);
    const addNewMeal = async () => {
        var newMealObj = {
            id: Math.floor(Math.random() * 10000) + new Date().getSeconds(),
            parentId: props.selectedType,
            plate_data: {
                name: newMealName,
                ingredients: newMealIngredients,
                new_photo: formDataFile
            },
            price: newMealPrice,
            menuType: props.menuType._id,
            type: props.selectedType,
        };
        if (!props.meals[props.selectedType].some(m => m.plate_data.name.toLowerCase().trim() === newMealObj.plate_data.name.toLowerCase().trim())) {
            if (
                newMealObj.plate_data.name !== '' && newMealObj.plate_data.ingredients !== '' && newMealObj.price !== ''
                && newMealObj.plate_data.photo !== false && newMealImage !== false
            ) {

                /** Check numeric value on price */
                if (!isNumeric(newMealPrice)) {
                    showDangerToast(t('enterNumericValueAsThePrice'));
                    return;
                }
                if (parseInt(newMealPrice) > MAX_PRICE) {
                    showDangerToast(t('priceCeilingExceeded'));
                    return;
                }

                try {
                    props.setShowLoading(true);

                    const result = await addNewMealFetch(
                        newMealObj.plate_data.name,
                        newMealObj.plate_data.ingredients,
                        newMealObj.price,
                        newMealObj.plate_data.new_photo,
                        newMealObj.menuType,
                        newMealObj.type
                    );
                    if (result.result === 'OK') {
                        props.fetchMenuTypeData(props.menuType);
                        resetForm();
                        showSuccessToast(t('mealCreatedCorrectly'));
                    } else {
                        fullError(t('anErrorOccurredDuringMealCreation'), result);
                    }
                } catch (error) {
                    if (!!error.response.data.msg && error.response.data.msg.toLowerCase().includes('already present')) {
                        fullError(t('mealAlreadyPresent'));
                    } else {
                        fullError(t('anErrorOccurredDuringMealCreation'), error);
                    }
                } finally {
                    props.setShowLoading(false);
                }

            } else {
                showDangerToast(t('enterAllParameters'));
            }
        } else {
            showDangerToast(t('mealAlreadyPresent'));
        }
    }
    const resetForm = () => {
        setNewMealName('');
        setNewMealIngredients('');
        setNewMealPrice('');
        setNewMealImage(false);
        setFormDataFile(false);
    }
    const editMealEvent = async () => {
        var prevMealObj = {
            id: props.meal.id,
            parentId: props.selectedType,
            plate_data: {
                name: newMealName,
                ingredients: newMealIngredients,
                new_photo: formDataFile
            },
            price: newMealPrice,
            menuType: props.menuType._id,
            type: props.selectedType
        };

        if (!props.meals[props.selectedType].some(m => (m.plate_data.name.toLowerCase().trim() === newMealName.toLowerCase().trim()) && m.id !== prevMealObj.id)) {
            if (newMealName !== '' && newMealIngredients !== '' && newMealPrice !== '' && newMealImage !== false) {

                /** Check numeric value on price */
                if (!isNumeric(newMealPrice)) {
                    showDangerToast(t('enterNumericValueAsThePrice'));
                    return;
                }
                if (parseInt(newMealPrice) > MAX_PRICE) {
                    showDangerToast(t('priceCeilingExceeded'));
                    return;
                }

                try {
                    props.setShowLoading(true);

                    const result = await editMealFetch(
                        prevMealObj.id,
                        prevMealObj.plate_data.name,
                        prevMealObj.plate_data.ingredients,
                        prevMealObj.price,
                        prevMealObj.plate_data.new_photo,
                        prevMealObj.menuType,
                        prevMealObj.type
                    );

                    if (result.result === 'OK') {
                        props.fetchMenuTypeData(props.menuType);
                        isEditProcess();
                        showSuccessToast(t('correctlyEditedMeal'));
                    } else {
                        fullError(t('anErrorOccurredDuringMealModification'), result);
                    }
                } catch (error) {
                    if (!!error.response.data.msg && error.response.data.msg.toLowerCase().includes('already present')) {
                        fullError(t('mealAlreadyPresent'));
                    } else {
                        fullError(t('anErrorOccurredDuringMealModification'), error);
                    }
                } finally {
                    props.setShowLoading(false);
                    setFormDataFile(false);
                }
            } else {
                showDangerToast(t('enterAllParameters'));
            }
        } else {
            showDangerToast(t('mealAlreadyExists'));
        }
    };

    const isEditProcess = () => {
        props.setIsEdit(false);
        props.setIsEditGlobal(false);
    };

    const loadingImage = (evento) => {
        const selectedFile = evento.target.files[0];

        setFormDataFile(selectedFile);

        if (selectedFile) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setNewMealImage(reader.result);
            };

            reader.readAsDataURL(selectedFile);
        }
        // resetto il valore o se carico la stessa immagine 2 volte, non viene presa.
        evento.target.value = null;
    };
    return (
        props.isAdmin && !props.isStaff &&
        <div className={"card shadow-sm px-2 " + (props.isEditGlobal === true ? 'disabled' : '')}>
            <input onChange={(event) => setNewMealName(event.target.value)} value={newMealName} type="text" id="newMealName" className="form-control mt-2" placeholder="Nome del nuovo pasto..." />
            <textarea onChange={(event) => setNewMealIngredients(event.target.value)} value={newMealIngredients} className="form-control mt-1" id="newMealIngredients" placeholder="Scrivi gli ingredienti..." rows="2"></textarea>
            <div className="card-body details d-flex ps-0">
                <div className="d-flex align-items-center me-3 newMealPriceBox">
                    <input onChange={(event) => setNewMealPrice(event.target.value)} value={newMealPrice} type="text" id="newMealPrice"
                        className="form-control newMealPrice me-2" placeholder="" /> €</div>
            </div>
            {!newMealImage && <div onClick={() => { document.getElementById('select-image').click(); }} className="newMealImage"><i className="bi bi-image icon"></i></div>}
            {newMealImage &&
                <div onClick={() => { document.getElementById('select-image').click(); }} className="editNewMealBox">
                    <i className="bi bi-pencil-square editNewMealImage icon"></i>
                    <img src={newMealImage} className="newMealImage foodImg w-100" />
                </div>
            }
            <input
                type="file"
                id="select-image"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={loadingImage}
            />
            {!props.meal ?
                <div className={"card-body buttonBox"}>
                    <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                        <div className="btn-group mb-2 mb-sm-0 col col-12 col-sm-6 quantityButton">
                            <button onClick={addNewMeal} type="button" className="btn btn-primary change" disabled={props.showLoading}>
                                {t('addNewMeal')}
                            </button>
                        </div>
                    </div>
                </div>
                :
                <div className="card-body buttonBox">
                    <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                        <div className="btn-group mb-2 mb-sm-0 col col-12 col-sm-6 quantityButton">
                            <button onClick={editMealEvent} type="button"
                                className="btn btn-primary change d-flex justify-content-center align-items-center" disabled={props.showLoading}>
                                <i onClick={editMealEvent} className="bi bi-pencil-square me-2"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>

            }
        </div>

    );
};

export default EditMeal;