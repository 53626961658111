import '../style/Staff.css';

import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { addStaffFetch, fetchRestaurantStaff, fetchUserIdByEmail, removeStaffFetch } from '../../services/api';
import { fetchPostProcess, fullError, showDangerToast } from '../../services/util';

const Staff = (props) => {
    const { t } = useTranslation();
    const [componentReady, setComponentReady] = useState(false);
    const [staff, setStaff] = useState([]);
    var [newStaffValue, setNewStaffValue] = useState('');

    useEffect(() => {
        props.setPageTo('staff');
    }, [props.setPageTo]);

    useEffect(() => {
        if (componentReady === false) {
            setComponentReady(() => {
                return true;
            });
        }
    }, []);
    useEffect(() => {
        if (componentReady && (staff.length === 0)) {
            fetchData();
        }
    }, [componentReady]);

    /** API */
    const fetchData = async () => {
        try {
            const staffList = await fetchRestaurantStaff();
            setStaff(staffList.data);
        } catch (error) {
        }
    };

    const addStaff = async () => {
        try {
            props.setShowLoading(true);
            const userIdResult = await fetchUserIdByEmail(newStaffValue);
            if (userIdResult.result === 'OK') {
                props.setShowLoading(false);
                fetchPostProcess(
                    () => addStaffFetch(userIdResult.data._id),
                    fetchData,
                    t('operationCompletedSuccessfully'),
                    t('errorDuringTheOperation'),
                    props
                );
                setNewStaffValue('');
            } else {
                props.setShowLoading(false);
                showDangerToast(t('errorDuringTheOperation'));
                
            }
        } catch (error) {
            props.setShowLoading(false);
            if (error.response.data.msg === "User not found") {
                fullError(t('userNotFound'), error);
            }
        }
    }
    const removeStaff = async (userId) => {
        fetchPostProcess(
            () => removeStaffFetch(userId),
            fetchData,
            t('operationCompletedSuccessfully'),
            t('errorDuringTheOperation'),
            props
        );
    }

    return (
        <>
            <a id="admin-section" className="adminSection customerList"></a>
            <div className="container list mt-3">
                {staff.length > 0 && staff.map(s => (
                    <div key={s._id} className="d-flex align-items-center customerRow">
                        <div className="d-flex align-items-center customerBox">
                            <i className="bi bi-person-fill me-2 icon"></i>
                            <div className="me-2 w-100 name">{s.username}</div>
                        </div>
                        <div className="confirmBox d-flex justify-content-center align-items-center">
                            <button onClick={() => removeStaff(s._id)} type="button"
                                className="btn btn-danger me-1 bi bi-trash deleteCustomer">
                            </button>
                        </div>
                    </div>
                ))}
                <div className="d-flex align-items-center customerRow">
                    <div className="input-group my-2">
                        <input type="text" className="form-control" value={newStaffValue}
                            placeholder={t('addStaffPlaceholder')} disabled={props.showLoading}
                            onChange={(event) => setNewStaffValue(event.target.value)} />
                        <button onClick={addStaff} className="btn btn-primary" type="button" disabled={props.showLoading}><i className="bi bi-plus icon me-2"></i></button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Staff;
