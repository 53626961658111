import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import {sendNewVerificationEmailFetch} from "../../../services/baseApi";
import {showDangerToast} from "../../../services/util";

const VerifyEmailPage = ({ setIsEmailToVerify }) => {
    const { t } = useTranslation();
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(null);

    const startCountdown = (nextResendDate) => {
        // Calcola il tempo rimanente in secondi
        const nextResendAt = new Date(nextResendDate);
        const now = new Date();
        let differenceInSeconds = Math.ceil((nextResendAt - now) / 1000);

        if(differenceInSeconds > 0) {
            // Imposta il tempo rimanente
            setTimeRemaining(differenceInSeconds);

            // Inizia il conto alla rovescia
            const countdownIntervalId = setInterval(() => {
                setTimeRemaining((prevTimeRemaining) => {
                    if (prevTimeRemaining <= 1) {
                        // Se il tempo è esaurito, cancella l'intervallo
                        clearInterval(countdownIntervalId);
                        return null;
                    } else {
                        // Altrimenti, riduci il tempo rimanente di 1
                        return prevTimeRemaining - 1;
                    }
                });
            }, 1000);
        }
    };


    useEffect(() => {
        const nextResendAtCookie = Cookies.get('nextResendAt');
        if (nextResendAtCookie) {
            startCountdown(nextResendAtCookie);
        }
    }, []);

    const handleSendNewEmail = async () => {
        setIsSendingEmail(true);
        let id = Cookies.get('isEmailToVerify');
        if (!id) {
            setIsEmailToVerify(false);
        } else {
            try {
                const result = await sendNewVerificationEmailFetch(id);
                if (result.result === 'OK') {
                    startCountdown(result.data.nextResendAt);
                    Cookies.set('nextResendAt', result.data.nextResendAt);
                }
                else
                {
                    showDangerToast(t('errorDuringTheOperation'));
                }
            } catch (error)
            {
                showDangerToast(t('errorDuringTheOperation'));
            }
        }
        setIsSendingEmail(false);
    }//TODO: Controllare che succede se togli i cookie

    const handleBackToLogin = () => {
        setIsEmailToVerify(false);
    };

    return (
        <div>
            <p>{t('VerifyEmailPageExplanation')}</p>
            <button
                id="sendNewVerificationEmailButton"
                className="w-100 btn btn-lg btn-primary mb-3"
                type="button"
                onClick={handleSendNewEmail}
                style={timeRemaining ? {minHeight: "4em"} : {}}
                disabled={isSendingEmail || timeRemaining != null} // Disabilitato se l'email è in invio o il timer è attivo
            >
                {timeRemaining ? `${t('SendNewVerificationEmail')} (${timeRemaining})` : t('SendNewVerificationEmail')}
            </button>
            <button className="w-100 btn btn-lg btn-secondary mb-3" type="button" onClick={handleBackToLogin}>
                {t('GoBackToLogin')}
            </button>
        </div>
    );
};

export default VerifyEmailPage;