import React, {useEffect,/*useEffect,*/ useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Login.css';
import App from './App';
import Login from './Login';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Cookies from 'js-cookie';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {PublicRoutes} from "./components/routes/PublicRoutes";
import { channel } from "./services/broadcastManager";
import useGlobalState from "./services/useGlobalState";

const RootComponent = () => { 
  const [isLoggedIn, setIsLoggedIn] = useGlobalState(!!(Cookies.get('xAuthToken')) && Cookies.get('isLoggedIn') === 'true');
  const [isEmailToVerify, setIsEmailToVerify] = useGlobalState(!!(Cookies.get('isEmailToVerify')));

  const setIsEmailToVerifyCookie = (value) => {
    setIsEmailToVerify(value);
    if(!value){
      Cookies.remove('isEmailToVerify');
      Cookies.remove('nextResendAt');
    } else {
      Cookies.set('isEmailToVerify', value);
    }
  };

    /** Modals */
    const [isRestaurantsModalOpen, setIsRestaurantsModalOpen] = useState(false);

  const [reloadOnNextClick, setReloadOnNextClick] = useState(false);

  useEffect(() => {
    const handleBroadcastMessage = (event) => {
      const { type } = event.data;

      switch (type) {
        case "reload_now":
          console.log("Reload immediato ricevuto");
          window.location.reload(); // Ricarica la pagina immediatamente
          break;

        case "lazy_reload":
          console.log("Reload ritardato al prossimo click ricevuto");
          setReloadOnNextClick(true); // Attiva il reload alla prossima interazione
          break;

        default:
          console.warn("Tipo di messaggio non riconosciuto:", type);
      }
    };

    const refreshTabs = () => {
      // Rimuovi il flag una volta eseguito il reload
      setReloadOnNextClick(false);

      // Ricarica la pagina
      window.location.reload();
    }

    const handleWindowFocus = () => {
      // Esegui reload se il flag `reloadOnNextClick` è attivo
      if (reloadOnNextClick) {
        refreshTabs()
      }
    };

    const handleMouseClick = (event) => {
      if (reloadOnNextClick) {
        console.log("Reload in corso dopo click su lazy_reload");

        // Blocca il comportamento predefinito del click
        event.preventDefault(); // Blocca azioni di default come link o submit del form
        event.stopPropagation(); // Interrompe la propagazione verso altri handler

        refreshTabs();
      }
    };

    channel.addEventListener("message", handleBroadcastMessage); // Ascolto messaggi
    document.addEventListener("click", handleMouseClick, true); // Ascolto click globali
    document.addEventListener("touchend", handleMouseClick, true);
    window.addEventListener("focus", handleWindowFocus);

    return () => {
      channel.removeEventListener("message", handleBroadcastMessage);
      document.removeEventListener("click", handleMouseClick);
      document.removeEventListener("touchend", handleMouseClick);
      window.removeEventListener("focus", handleWindowFocus);
    };
  }, [reloadOnNextClick]);

  return (
      <React.StrictMode>
        <Router>
          <Routes>
            {PublicRoutes(setIsRestaurantsModalOpen, setIsEmailToVerifyCookie, setIsLoggedIn,isLoggedIn)}
            <Route path="/*" element={
              isLoggedIn && !isEmailToVerify
                  ? <App />
                  : <Login setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} setIsEmailToVerify={setIsEmailToVerifyCookie} isEmailToVerify={isEmailToVerify} isRestaurantsModalOpen={isRestaurantsModalOpen} setIsRestaurantsModalOpen={setIsRestaurantsModalOpen}/>
            } />
          </Routes>
        </Router>
      </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
