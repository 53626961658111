import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';

import { Route, Routes, Navigate, useSearchParams, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { fullError, showDangerToast, showSuccessToast, AUTOLOGIN_CODE } from './services/util';
import Cookies from 'js-cookie';

/** Services */
import { guestLoginFetch, loginFetch, registerFetch } from './services/baseApi';
import LoginForm from './components/form/LoginForm';
import RegisterForm from './components/form/RegisterForm';

import GoogleLoginCallback from './components/pages/externalLogins/GoogleLoginCallback';

/** Modals */
import RestaurantsModal from './components/modals/RestaurantsModal';

import { GoogleOAuthProvider } from '@react-oauth/google';
import FacebookLoginCallback from "./components/pages/externalLogins/FacebookLoginCallback";
import VerifyEmailPage from "./components/pages/externalLogins/VerifyEmailPage";
import { loginProcess as loginProcessExternal } from "./services/login";
import LoadingComponent from "./components/loader";
import { getGuestLoginId } from './services/api';

const Login = ({ setIsLoggedIn, isLoggedIn, isEmailToVerify, setIsEmailToVerify, isRestaurantsModalOpen, setIsRestaurantsModalOpen }) => {
    const { t } = useTranslation();
    const [emailValue, setEmailValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
    const [consentValue, setConsentValue] = useState(false);

    const [showError, setShowError] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    // Stato per tracciare se il login automatico è già stato tentato
    const [autoLoginAttempted, setAutoLoginAttempted] = useState(false);

    // Ottieni i parametri della query string
    const [searchParams] = useSearchParams();
    const autoLogin = searchParams.get('auto-login'); // Recupera il parametro autoLogin
    const navigate = useNavigate();

    const guestLogin = async () => {
        try {
            setShowLoading(true);
            let result = false;
            if ( !! getGuestLoginId()) {
                result = await guestLoginFetch(getGuestLoginId());
            } else {
                result = await guestLoginFetch();
            }                                  
            Cookies.set('guestLoginId', result.data.userId);
            loginProcess(result);
        } catch (error) {
            fullError(t('anErrorOccurredDuringLogin'), error);
        } finally {
            setShowLoading(false);
        }
    }

    // Esegui il login automatico direttamente durante il rendering
    if (autoLogin === AUTOLOGIN_CODE && !autoLoginAttempted) {
        // Modifica l'URL visualizzato per nascondere il prima possibile il valore di autologin
        navigate('/', { replace: true });
        setAutoLoginAttempted(true);
        guestLogin();

    }

    const handleKeyUp = (event) => {
        if (event.key === 'Enter') {
            login();
        }
    }
    const loginProcess = (result) => {
        loginProcessExternal(result, setIsRestaurantsModalOpen, setIsEmailToVerify, setIsLoggedIn, setShowError);
    }
    const login = async () => {
        try {
            setShowLoading(true);
            const result = await loginFetch(emailValue.trim().toLowerCase(), passwordValue);
            loginProcess(result);
        } catch (error) {
            if (error.response.data.error === 'Unverified email') {
                setIsEmailToVerify(error.response.data.id);
                if (error.response.data.nextResendAt) {
                    Cookies.set('nextResendAt', error.response.data.nextResendAt);
                }
            }
            else if (error.response.data.msg === 'User not found') {
                setShowError(true);
            } else {
                fullError(t('anErrorOccurredDuringLogin'), error);
            }
        } finally {
            setShowLoading(false);
        }
    }
    const register = async () => {
        try {
            setShowLoading(true);
            if (
                emailValue.trim() !== '' && passwordValue.trim() !== ''
                && confirmPasswordValue.trim() !== '' && consentValue === true
            ) {
                if (passwordValue === confirmPasswordValue) {

                    const result = await registerFetch(emailValue, emailValue, passwordValue, 'customer');

                    if (result.result === 'OK') {
                        showSuccessToast(t('successfulRegistration'));
                        setShowError(false);
                        return true;
                    }
                } else {
                    setIsLoggedIn(false);
                    showDangerToast(t('passwordAndConfirmDoNotMatch'));
                }
            } else {
                showDangerToast(t('fillAllFields'));
            }
        } catch (error) {
            if (error.response.data.msg === 'User already exists') {
                fullError(t('userAlreadyExists'), error);
            } else {
                fullError(t('anErrorOccurredDuringRegister'), error);
            }
        } finally {
            setShowLoading(false);
        }
    }
    return (
        <main className="login form-signin text-center">
            <RestaurantsModal setShowLoading={setShowLoading} setIsLoggedIn={setIsLoggedIn}
                isRestaurantsModalOpen={isRestaurantsModalOpen} setIsRestaurantsModalOpen={setIsRestaurantsModalOpen} />
            <LoadingComponent showLoading={showLoading} />
            <ToastContainer
                position="top-center"
                autoClose={1500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="light"
                transition="Bounce"
            />
            <form>
                <a href="/"><img className="mb-3 loginLogo" src="/img/svg/logo.svg" alt="" /></a>

                {showError &&
                    <div className="alert alert-danger" role="alert">
                        {t('incorrectCredentials')}
                    </div>
                }
                <Routes>
                        /*ROUTE PUBBLICHE, ACCESSIBILI SEMPRE TRANNE DA LOGGATO*/
                    <Route
                        path="/login/google/callback"
                        element={<GoogleLoginCallback clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID} loginProcess={loginProcess} setShowLoading={setShowLoading} />}
                    />
                    <Route
                        path="/login/facebook/callback"
                        element={<FacebookLoginCallback clientId={process.env.REACT_APP_FACEBOOK_OAUTH_CLIENT_ID} loginProcess={loginProcess} setShowLoading={setShowLoading} />}
                    />

                    {isEmailToVerify ?
                        <>
                            <Route
                                path="*"
                                element={<Navigate to='/verify-email' />}
                            />
                            <Route
                                path="/verify-email"
                                element={<VerifyEmailPage setIsEmailToVerify={setIsEmailToVerify} isEmailToVerify={isEmailToVerify} />}
                            />
                        </>
                        :
                        <>
                            <Route
                                path="/"
                                element={
                                    <>
                                        <GoogleOAuthProvider
                                            clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
                                        >
                                            <LoginForm loginProcess={loginProcess} login={login} guestLogin={guestLogin} handleKeyUp={handleKeyUp} setEmailValue={setEmailValue}
                                                setPasswordValue={setPasswordValue} setShowLoading={setShowLoading} />
                                        </GoogleOAuthProvider>
                                    </>
                                }
                            />
                            <Route
                                path="/register"
                                element={<RegisterForm register={register} setEmailValue={setEmailValue} setPasswordValue={setPasswordValue}
                                    setConfirmPasswordValue={setConfirmPasswordValue} setConsentValue={setConsentValue} />}
                            />

                            {!isLoggedIn &&
                                <Route path='*' element={<Navigate to='/' />} />
                            }
                        </>
                    }
                </Routes>
            </form>
            <p className="mt-5 mb-2 copyright">© 2024</p>


        </main >
    );
};

export default Login;