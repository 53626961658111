import React, { useState } from 'react';
/** Bootstrap */
import { Modal, Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import { fetchPostProcess } from '../../services/util';
import { setLegalNoticesOfRestaurant } from '../../services/api';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importa lo stile predefinito di Quill


const InfoModal = (props) => {
    const { t } = useTranslation();
    const [isEdit, setIsEdit] = useState(false);

    const save = () => {
        fetchPostProcess(
            () => setLegalNoticesOfRestaurant(props.infoApi),
            () => props.fetchInfoData(),
            t('saveCompletedSuccessfully'),
            t('errorDuringTheOperation'),
            props
        );
        setIsEdit(false);
    }
    const handleChange = (html) => {
        props.setInfoApi(html);
    };

    return (
        <Modal show={props.isInfoModalOpen} onHide={() => props.setIsInfoModalOpen(false)} size="lg" centered>
            <Modal.Header>
                <Modal.Title>{t('informative')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-2">
                {((props.isAdmin && !props.isStaff) && isEdit === true) ?
                    <ReactQuill value={props.infoApi} onChange={handleChange} />
                    :
                    <div dangerouslySetInnerHTML={{ __html: props.infoApi }}></div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.setIsInfoModalOpen(false)}>
                    {t('close')}
                </Button>
                {((props.isAdmin && !props.isStaff) && isEdit === false) && <Button variant="warning" onClick={() => setIsEdit(true)}>
                    {t('edit')}
                </Button>}
                {((props.isAdmin && !props.isStaff) && isEdit === true) && <Button variant="primary" onClick={save}>
                    {t('save')}
                </Button>}
            </Modal.Footer>

        </Modal>
    );
};

export default InfoModal;