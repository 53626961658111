import React from 'react';
import ReactLoading from 'react-loading';

const LoadingComponent = ({ showLoading }) => {
    return showLoading ? (
        <div>
            <div className="fade modal-backdrop show"></div>
            <ReactLoading className="loader" type={"bubbles"}
                          style={{
                              position: 'fixed',
                              width: '300px',
                              zIndex: 9999,
                              top: '30%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                          }} />
        </div>
    ) : null;
};

export default LoadingComponent;