import React, {useCallback, useEffect} from "react";
import Cookies from 'js-cookie';
import {fetchLoginGoogle} from "../../../services/api";
import {fullError} from "../../../services/util";
import {useTranslation} from "react-i18next";
import LoginForm from "../../form/LoginForm";
import {GoogleOAuthProvider} from "@react-oauth/google";

const GoogleLoginCallback = (props) => {
    const { t } = useTranslation();

    const completeGoogleLogin = useCallback(async () => {

        const cookieValue = Cookies.get('gtoken');

        try {
            if (!cookieValue) {
                throw new Error(t('CookieTokenNotValid'));
            }

            const result = await fetchLoginGoogle(cookieValue);
            props.loginProcess(result);
        } catch (error) {
            console.log("anErrorOccurred");
            fullError(t('anErrorOccurred'), error);
        }

    }, []);

    useEffect(() => {
        props.setShowLoading(true);

        completeGoogleLogin().then(() => {props.setShowLoading(false)});


    }, [completeGoogleLogin]);

    return (<GoogleOAuthProvider
            clientId={props.clientId}
        >
            <LoginForm loginProcess={props.loginProcess} setShowLoading={props.setShowLoading} />
        </GoogleOAuthProvider>
    );
}

export default GoogleLoginCallback;