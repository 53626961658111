import '../style/Menu.css';

import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { createCategoryFetch } from '../../services/api';

import { showDangerToast, fetchPostProcess } from '../../services/util';

import Cookies from 'js-cookie';

/** Services */
import MenuList from './MenuList';
import MyNavbar from '../menu/MyNavbar';
import ContactUsModal from '../modals/ContactUsModal';
import BottomList from './BottomList';

function Menu(props) {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [selected, setSelected] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [menu, setMenu] = useState([]);
    const [adminMenu, setAdminMenu] = useState([]);
    const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);
    const [prevPage, setPrevPage] = useState(false);

    /** Input and Handle */
    const [newCategoryValue, setNewCategoryValue] = useState('');

    useEffect(() => {
        if (props.isAssigned === false && props.isAdmin === false && props.isStaff === false && props.isFetchCompleted === true) {
            // Cookies.remove('sessionId');
            navigate('qr-code');
        }
    }, [props.isAssigned, props.isAdmin, props.isFetchCompleted]);

    useEffect(() => {
        let changeSelected = ((props.page !== prevPage && props.page !== 'home')) ? true : false;
        if (changeSelected) {
            setPrevPage(props.page);
            setSelected(props.page);
        }
    }, [props.page]);
    useEffect(() => {
        if (props.menuChosen === true) {
            const menuData = props.originalMeals;
            setMenu(menuData);
            setSelectedProcess(menuData);
            props.setMenuChosen(false);
        }
    }, [props.originalMeals, props.menuChosen]);
    useEffect(() => {
        const fetchData = async () => {
            if (menu.length === 0 && props.showLoading === false && props.originalMeals.length > 0) {
                const menuData = props.originalMeals;
                setMenu(menuData);
                if (props.page === 'home') {
                    setSelectedProcess(menuData);
                }
            }
        };
        if (props.showLoading === false) fetchData();
        const adminMenuData = {
            orderList: t('orderList'),
            // customerList: t('customerList'), //Spostato ad eventuale funzionalità premium futura (cartella premium)
            tableList: t('tableList'),
            readyOrders: t('readyOrders')
        };
        if (!!props.page && Object.keys(adminMenuData).includes(props.page) && props.page !== 'settings' && props.isAdmin === true) setSelected(adminMenuData[props.page]);
        if (props.showLoading === false) setAdminMenu(Object.entries(adminMenuData));

    }, [props.page, props.showLoading, props.originalMeals]);

    const setSelectedProcess = (menuData) => {
        setSelected(prevSelected => {
            if (prevSelected !== '') {
                return prevSelected;
            } else {
                return menuData._id;
            }
        });
    }
    const handleKeyUpAddCategory = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (newCategoryValue.trim() !== '') {
                addCategory(event.target.value);
            } else {
                showDangerToast(t('enterCategoryName'))
            }
        }
    }
    const addCategory = async () => {
        var foundMealName = menu.categories.find(m => m.name.toLowerCase() === newCategoryValue.toLowerCase().trim());
        if (typeof foundMealName === 'undefined') {
            if (newCategoryValue.trim() !== '') {
                fetchPostProcess(
                    () => createCategoryFetch(props.menuTypeSelected._id, newCategoryValue.toLowerCase()),
                    () => props.fetchMenuTypeData(props.menuTypeSelected),
                    t('categoryAddedCorrectly'),
                    t('anErrorOccurredDuringCategoryAddition'),
                    props
                );
            } else {
                showDangerToast(t('enterANameForTheCategory'));
            }
        } else {
            showDangerToast(t('categoryAlreadyPresent'));
        }
        setNewCategoryValue('');
    };
    const changeMeals = (type) => {
        setSelected(type);
        if (props.isAssigned === true || props.isAdmin) navigate("/");
        props.changeMeals(type);
    };
    return (
        <>
            <div className="menu d-none d-sm-flex">
                <div className="d-flex flex-column flex-shrink-0 text-white bg-dark sidebar">
                    <ContactUsModal isContactUsModalOpen={isContactUsModalOpen} setIsContactUsModalOpen={setIsContactUsModalOpen} setShowLoading={props.setShowLoading} />
                   
                    <MenuList key={'menulist-menu'} setSelectedType={props.setSelectedType} isAssigned={props.isAssigned} menuTypeSelected={props.menuTypeSelected}
                        handleKeyUpAddCategory={handleKeyUpAddCategory} addCategory={addCategory} isNavbar={false} menu={menu}
                        setShowLoading={props.setShowLoading} showLoading={props.showLoading} setSelected={setSelected} selected={selected}
                        setNewCategoryValue={setNewCategoryValue} newCategoryValue={newCategoryValue} setMenu={setMenu} isAdmin={props.isAdmin} isStaff={props.isStaff} isEdit={props.isEdit}
                        changeMeals={changeMeals} setIsEdit={setIsEdit} adminMenu={adminMenu} fetchMenuTypeData={props.fetchMenuTypeData} setIsModalOpen={props.setIsModalOpen} />
                  
                    <BottomList setShowLoading={props.setShowLoading} setSelected={setSelected} selected={selected}
                        isAssigned={props.isAssigned} isAdmin={props.isAdmin} isStaff={props.isStaff} setIsContactUsModalOpen={setIsContactUsModalOpen}/>
                </div>
            </div>
            <MyNavbar fetchMenuTypeData={props.fetchMenuTypeData} isAssigned={props.isAssigned} menuTypeSelected={props.menuTypeSelected} setIsModalOpen={props.setIsModalOpen} menu={menu} setShowLoading={props.setShowLoading}
                showLoading={props.showLoading} selected={selected} setNewCategoryValue={setNewCategoryValue} setMenu={setMenu}
                isAdmin={props.isAdmin} isStaff={props.isStaff} isEdit={isEdit} changeMeals={changeMeals} setIsEdit={setIsEdit}
                handleKeyUpAddCategory={handleKeyUpAddCategory} addCategory={addCategory} adminMenu={adminMenu}
                setSelected={setSelected} setIsContactUsModalOpen={setIsContactUsModalOpen} />
        </>
    );
}
export default Menu;